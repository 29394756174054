<template>
	<div>
		<div>
			<VPopup :closePopup="onClosePopup">
				<template #header>
					<div>
						{{ formName }}
					</div>
				</template>
				<template #body>
					<div style="margin-top: 35px">
						<CreateCustomerVisitSubComponent
							keyCreateCustomerVisitFrame="keyCreateCustomerVisitFrame"
						/>
					</div>
				</template>
				<template #footer>
					<div>
						{{ formName }}
					</div>
				</template>
			</VPopup>
		</div>
	</div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import VPopup from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/VPopup.vue'
import CreateCustomerVisitSubComponent from '@/_srcv2/pages/business-development/customer-visits/components/CreateCustomerVisitSubComponent.vue'
import useCreateCustomerVisitState from '@/_srcv2/pages/business-development/customer-visits/scripts/useCreateCustomerVisitState.js'

export default {
	name: 'CreateCustomerVisitFrame',
	components: {
		VPopup,
		CreateCustomerVisitSubComponent,
	},
	setup() {
		const getFormName = () => {
			if (noteType.value === 'visitNote') {
				return 'Create Customer Visit'
			} else if (noteType.value === 'alarmNote') {
				return 'Create an Alarm Note'
			} else {
				return ''
			}
		}
		// 'Create Customer Visit'
		const { isFrameVisible, mutationType, noteType } =
			useCreateCustomerVisitState()
		// todo ------------------------------------------------
		const formName = ref('')
		onMounted(() => {
			formName.value = getFormName()
		})
		const onClosePopup = () => {
			isFrameVisible.value = false
		}
		return {
			mutationType,
			formName,
			onClosePopup,
			isFrameVisible,
		}
	},
}
</script>

<style lang="scss" scoped></style>
