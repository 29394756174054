/** @format */

import { reactive, ref, toRefs } from '@vue/composition-api'

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not share the state
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

const isModalVisible = ref(false)

const sortOptions = reactive({
	enabled: true,
	multipleColumns: true,
	initialSortBy: [
		{ field: 'dayOfWeek', type: 'asc' },
		{ field: 'routeId', type: 'asc' },
		{ field: 'postCode', type: 'asc' },
		{ field: 'line', type: 'asc' },
	],
})
const keyRouteTable = ref(1)
const selectedAddress = ref({})
const modelAddress = reactive({
	addressNickname: '',
	addressId: '',
	companyId: '',
	ownerId: '',
	ownerTitle: '',
	ownerNickname: '',
	routeId: '',
	line: '',
	city: '',
	postCode: '',
	phone: '',
	person: '',
	listGroup: '',
	openHours: '',
})
const schemaAddress = reactive({
	groups: [
		{
			legend: 'Enter the route',
			id: 'addresses',
			featured: true,
			fields: [
				{
					type: 'input',
					inputType: 'text',
					label: 'Address Nickname',
					model: 'addressNickname',
					id: 'address-nickname',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Address Owner Id',
					model: 'ownerId',
					id: 'owner-id',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Address Owner Title',
					model: 'ownerTitle',
					id: 'owner-title',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Address Owner Nickname',
					model: 'ownerNickname',
					id: 'owner-nickname',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Route Id',
					model: 'routeId',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Address Line',
					model: 'line',
					placeholder: 'Please enter address line',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'City',
					model: 'city',
					placeholder: 'Please enter city',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Post Code',
					model: 'postCode',
					placeholder: 'Please enter post code',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'textArea',
					label: 'Open Hours',
					model: 'openHours',
					hint: 'Max 300 characters',
					max: 300,
					placeholder: 'Open Hours',
					rows: 2,
					validator: ['string'],
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Phone',
					model: 'phone',
					placeholder: 'Please enter phone',
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Person',
					model: 'person',
					placeholder: 'Please enter person',
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
				},
				{
					type: 'select',
					label: 'List Group',
					model: 'listGroup',
					required: true,
					values: [
						'Call Every Week',
						'Call Monthly',
						'Visit Monthly',
						'Customer Calls Us',
						'Other Customers',
						'Only Invoice Address',
					],
					default: 'Call Every Week',
					validator: ['string'],
				},
			],
		},
	],
})
const formOptionsAddress = reactive({
	validateAfterLoad: false,
	validateAfterChanged: true,
	validateAsync: true,
	fieldIdPrefix: 'TelNum',
})

export default function useRouteListState() {
	return {
		isModalVisible,
		selectedAddress,
		modelAddress,
		schemaAddress,
		formOptionsAddress,
		sortOptions,
		keyRouteTable,
		...toRefs(modelAddress),
	}
}
