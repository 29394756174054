/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not share the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'

Vue.use(VueCompositionApi)

const state = reactive({
	keyCreateCustomerVisitFrame: 1,
	isFrameVisible: false,
	isAllVisitableCustomersModalFrameVisible: false,
	isPreviewVisitListVisible: false,
	fireRefetch: 1,
	columnsDailyVisitList: [
		{
			label: 'Select',
			field: 'select',
			width: '25px',
			sortable: false,
		},
		{
			label: 'Alarm',
			field: 'alarm',
			width: '25px',
			sortable: false,
		},
		{
			label: 'Visit Date',
			field: 'visit_date',
			width: '85px',
		},
		{
			label: 'Customer',
			field: 'customer',
			width: '100px',
		},
		{
			label: 'Notes',
			field: 'visit_notes',
			width: '360px',
		},
		{
			label: 'Visited or Not',
			field: 'is_customer_visited',
			width: '66px',
		},
		{
			label: 'Edit',
			field: 'edit',
			width: '25px',
			sortable: false,
		},
	],
	columnsDailyAlarmList: [
		{
			label: 'Select',
			field: 'select',
			width: '35px',
			sortable: false,
		},
		{
			label: 'Alarm',
			field: 'alarm',
			width: '25px',
			sortable: false,
		},
		{
			label: 'Alarm Date',
			field: 'visit_alarm_date',
			width: '106px',
		},
		{
			label: 'Customer',
			field: 'customer',
			width: '100px',
		},
		{
			label: 'Notes',
			field: 'visit_alarm_notes',
			width: '330px',
		},
		{
			label: 'Is Alarm Active',
			field: 'is_alarm_active',
			width: '66px',
		},
		{
			label: 'Edit',
			field: 'edit',
			width: '25px',
			sortable: false,
		},
	],
})

// todo Define the form ----------------------------------------------------------
const modelVisit = reactive({
	mutationType: '',
	noteType: '',
	visitID: '',
	customer: '',
	customerID: '',
	addressID: '',
	visitNotes: '',
	isCustomerVisited: false,
	visitAlarmNotes: '',
	isAlarmActive: true,
})
const schemaVisit = reactive({
	groups: [
		{
			legend: 'Visiting Details',
			id: 'visiting-details',
			featured: true,
			fields: [
				// todo -----------------------------------------------
				{
					type: 'input',
					inputType: 'text',
					label: 'Customer',
					model: 'customer',
					id: 'customer',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					// ? Only appear if packet value is true
					visible(model) {
						return model && model.noteType === 'visitNote'
					},
					type: 'textArea',
					label: 'Visit Notes',
					model: 'visitNotes',
					hint: 'Max 500 characters',
					max: 500,
					placeholder: 'Enter visit note',
					rows: 5,
				},
				{
					// ? Only appear if packet value is true
					visible(model) {
						return (
							model &&
							model.noteType === 'visitNote' &&
							model.mutationType === 'edit'
						)
					},
					type: 'radios',
					label: 'Choose if visit is done or not',
					model: 'isCustomerVisited',
					values: [
						{ name: 'Visited', value: true },
						{ name: 'Not Visited', value: false },
					],
				},
				// todo -----------------------------------------------
				{
					// ? Only appear if packet value is true
					visible(model) {
						return model && model.noteType === 'alarmNote'
					},
					type: 'textArea',
					label: 'Visit Alarm Notes',
					model: 'visitAlarmNotes',
					id: 'visit-alarm-notes',
					hint: 'Max 500 characters',
					max: 500,
					placeholder: 'Enter alarm note',
					rows: 5,
				},
				{
					// ? Only appear if packet value is true
					visible(model) {
						return (
							model &&
							model.noteType === 'alarmNote' &&
							model.mutationType === 'edit'
						)
					},
					type: 'radios',
					label: 'Choose if alarm is active or not',
					model: 'isAlarmActive',
					values: [
						{ name: 'Active', value: true },
						{ name: 'Not Active', value: false },
					],
				},
			],
		},
	],
})
const formOptionsVisit = reactive({
	validateAfterLoad: true,
	validateAfterChanged: true,
	validateAsync: true,
	fieldIdPrefix: 'invoiceForm',
})

export default function useCreateCustomerVisitState() {
	return {
		...toRefs(state),
		modelVisit,
		schemaVisit,
		formOptionsVisit,
		...toRefs(modelVisit),
	}
}
